@import "../../../style/variables";

.admin-dashboard-component {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  padding: 24px;
  background-color: $color-gray0;

  & > * {
    box-sizing: border-box;
  }
}

@import '../../../style/variables';

.filters-modal-component {
  display: flex;
  flex-direction: column;

  padding: 16px 32px;
  width: 458px;

  box-sizing: border-box;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;

    .filter-title {
      font-size: 20px;
      line-height: 24x;
      font-family: $fontRegular;
      color: $color-black;
      margin-bottom: 8px;
    }

    .status-item-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      border: 1px solid $color-gray40;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 8px;
    }
  }

  .pickup-date-container {
    display: flex;
    flex-direction: row;
  
    .from {
      margin-right: 16px;
    }
  }
}

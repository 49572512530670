@import "../../style/variables";

.home-component {
  @media screen and (max-width: 600px) {
    width: 90vw;
    .title {
      font-size: 32px;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
    }
    .buttons-container div {
      width: 130px;
    }
  }

  @media screen and (min-width: 600px) {
    width: 90vw;
    .title {
      font-size: 48px;
    }
    .subtitle {
      font-size: 20px;
    }
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  .title {
    line-height: 56px;
    font-family: $fontSemiBold;
    color: $color-white;
    margin-bottom: 24px;
    text-align: center;
  }

  .subtitle {
    line-height: 28px;
    font-family: $fontRegular;
    color: $color-white;
    margin-bottom: 64px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .create-button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 20px;
    font-family: $fontSemiBold;
    color: $color-black;
    margin-right: 8px;
    padding: 8px;
    cursor: pointer;

    height: 20px;
    width: 160px;
    border-radius: 5px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    margin-right: 16px;
  }

  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 20px;
    font-family: $fontSemiBold;
    color: $color-primary;
    margin-right: 8px;
    padding: 8px;
    cursor: pointer;

    height: 20px;
    width: 160px;
    border-radius: 5px;
    border: 1px solid $color-primary;
  }

  .social-media-container {
    margin-top: 48px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      color: white;
    }
  }
}

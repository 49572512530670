@import "../../../../style/variables";

.owes-report-view-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;

  padding: 24px;
  background-color: $color-gray0;

  & > * {
    box-sizing: border-box;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-right: 16px;
    margin-bottom: 24px;

    .title-text {
      color: $color-black;
      font-family: $fontSemiBold;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-right: 0.8rem;
    }

    .title-count {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-gray40;
      border-radius: 0.4rem;
      color: $color-gray80;
      font-family: $fontLight;
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding: 0.4rem;
    }
  }

  .invoice-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid $color-gray40;

    &,
    & > * {
      box-sizing: border-box;
    }

    .info-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        .info-label {
          width: 150px;
          margin-right: 8px;
        }
      }

      .view-order-button {
        text-align: center;
        margin-top: 16px;
        color: $color-blue;
        text-decoration: underline;
      }
    }

    .vertical-divider {
      display: flex;
      width: 1px;
      background-color: $color-gray40;
      margin-left: 16px;
      margin-right: 16px;
    }

    .actions-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;

      .payment-type-select {
        width: 200px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid $color-gray60;
        background-color: $color-white;
        padding: 8px 10px;

        &:focus {
          outline: none;
          border: 1px solid $color-blue;
        }
      }

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        border-radius: 8px;
        padding: 0 16px;
        cursor: pointer;
        background-color: $color-blue;
        color: $color-white;
        height: 40px;
      }
    }
  }
}

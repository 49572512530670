@import '../../../style/variables';

.terms-and-conditions-modal-component {
  display: flex;
  flex-direction: column;

  padding: 32px 80px;
  width: 440px;
  position: relative;

  overflow-y: auto;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .paragraph {
    margin-bottom: 30px;
    font-size: 14px;
  }
  .paragraph-title {
    font-size: 16px;
    font-weight: 600;
  }

  .signature-container {
    width: fit-content;

    .canvas-container {
      margin-top: -10px;

      .clear-canvas-button {
        margin-bottom: -26px;
        position: relative;
        left: 430px;
        scale: 0.75;
        cursor: pointer;
      }

      .signature-canvas {
        border: 1px solid black;
      }
    }
  }

  .accept-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  .accept-btn {
    width: 200px;
    height: 50px !important;
    background-color: $color-primary;
    text-align: center;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 12px;

    font-size: 16px;
    line-height: 32px;
    font-family: $fontMedium;
    color: $color-black;

    margin-top: 24px;
    padding: 8px;
  }

  .disabled {
    opacity: 40%;
    cursor: default;
  }
}

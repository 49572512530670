@import '../../../style/variables';

.schedules-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  overflow-y: hidden;

  background-color: $color-gray0;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: 20px;

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: start;
  
      font-size: 16px;
      line-height: 20px;
      font-family: $fontSemiBold;
      color: $color-primary;
      padding: 8px;
      cursor: pointer;
  
      height: 20px;
      width: 160px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }

    .icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon-container {
      height: 24px;
      width: 24px;
      margin-right: 8px;
      cursor: pointer;

      .icon {
        height: 24px;
        width: 24px;
        color: $color-gray80;
      }
    }
    
    .date-text {
      font-size: 18px;
      line-height: 24px;
      font-family: $fontMedium;
      color: $color-gray100;
      margin-left: 16px;
    }
  }

  .content-container {
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: row;
    overflow: hidden;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        color: $color-black;
        font-family: $fontSemiBold;
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin-right: 0.8rem;
      }

      .title-count {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray40;
        border-radius: 0.4rem;
        color: $color-gray80;
        font-family: $fontLight;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.2rem;
      }
    }

    .schedules-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 10px;

      margin-right: 20px;
      overflow-x: scroll;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .order-items-container {
      display: flex;
      flex-direction: column;
      width: 256px;
    }

    .deliveries-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 20px;
      overflow-y: auto;
    }

    .pick-ups-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 20px;
      overflow-y: auto;
    }

    .dump-yards-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 20px;
      overflow-y: auto;
    }

    .storages-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 20px;
      overflow-y: auto;
    }

    .quick-stops-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-gray40;
      border-radius: 5px;
      padding: 8px;
      overflow-y: auto;
    }

    .heading-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 16px;
        line-height: 20px;
        font-family: $fontRegular;
        color: $color-gray100;
      }

      .title-count {
        font-size: 16px;
        line-height: 20px;
        font-family: $fontLight;
        color: $color-gray60;
      }
    }

    .schedule-items-container {
      display: flex;
      flex: 1;
      overflow-x: auto;

      margin-top: 10px;
    }

    .small-collapse-icon {
      width: 12px;
      height: 12px;
      color: $color-gray100;
    }

    .delivery-items-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
    }

    .dump-yard-items-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
    }

    .pickup-items-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
    }

    .items-collapse {
      width: fit-content;
      cursor: pointer;
    }
  }
}

@import '../../../../style/variables';

.order-details-card-component {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  padding-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  .title {
    color: $color-black;
    font-family: $fontSemiBold;
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 24px;
    margin-left: 24px;
  }

  .details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;

      .item-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;

        .label {
          width: 160px;
          font-size: 14px;
          line-height: 18px;
          font-family: $fontRegular;
          color: $color-gray80;
        }

        .value {
          font-size: 14px;
          line-height: 18px;
          font-family: $fontRegular;
          color: $color-black;
        }
      }
    }
  }
}

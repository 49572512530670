@import '../../../../style/variables';

.profile-info-card-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;

  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      margin-right: 16px;
      background-color: $color-blue;
      border-radius: $border-radius;
    }

    .name {
      color: $color-black;
      font-family: $fontSemiBold;
      line-height: 40px;
      font-size: 32px;
      margin-bottom: 4px;
    }

    .role {
      color: $color-gray60;
      font-family: $fontSemiBold;
      line-height: 32px;
      font-size: 20px;
    }
  }

  .balance-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .create-order-button {
      background-color: $color-blue;
      border-radius: $border-radius;
      padding: 8px 16px;

      color: $color-white;
      font-family: $fontMedium;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;

      :hover {
        cursor: pointer;
      }
    }
  }
}

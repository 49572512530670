@import '../../../style/variables';

.order-component {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;

  background-color: $color-gray0;

  &, & > * {
    box-sizing: border-box;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;

    border: 1px solid $color-gray40;
    border-radius: 5px;
    padding: 16px;

    .title {
      font-size: 24px;
      line-height: 32px;
      font-family: $fontRegular;
      color: $color-black;

      align-self: center;
      margin-bottom: 16px;
    }

    .no-invoice-placeholder {
      text-align: center;
      font-size: 16px;
      line-height: 20em;
      font-family: $fontSemiBold;
      color: $color-black;
    }

    .item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;

      .item-label {
        font-size: 16px;
        line-height: 20px;
        font-family: $fontRegular;
        color: $color-gray80;
        margin-right: 8px;
      }

      .item-value {
        font-size: 16px;
        line-height: 20px;
        font-family: $fontSemiBold;
        color: $color-black;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 200px;
        margin: 4px;
        border: 1px solid $color-gray40;
        border-radius: 5px;
        cursor: pointer;
      }

      .icon {
        margin-right: 8px;
      }

      .button-text {
        font-size: 16px;
        line-height: 20px;
        font-family: $fontRegular;
        color: $color-black;
      }
    }
  }
}

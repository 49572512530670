@import '../../../../style/variables';

.client-order-history-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  padding: 24px;
  background-color: $color-gray0;

  & > * {
      box-sizing: border-box;
  }

  .back-button-container {
    margin-bottom: 2.4rem;
  }
}

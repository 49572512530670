@import "../../style/variables";

.navigation-bar-component {
  @media screen and (max-width: 600px) {
    .link {
      font-size: 12px;
    }
    .link-navigation {
      font-size: 12px;
      padding: 2px;
      text-align: center;
      line-height: 10px;
    }
    .logo {
      margin-right: 16px;
      width: 40px;
    }
  }

  @media screen and (min-width: 600px) {
    .link {
      font-size: 16px;
    }
    .link-navigation {
      font-size: 16px;
      padding: 8px;
      line-height: 20px;
    }
    .logo {
      margin-right: 64px;
      width: 60px;
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100vw;

  
  .left-controls-container {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      line-height: 20px;
      font-family: $fontMedium;
      color: $color-gray0;
      margin-right: 8px;
      cursor: pointer;
      margin-right: 16px;

      &.selected {
        text-underline-offset: 2px;
        text-decoration: underline;
      }
    }
  }

  .right-controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    .link-navigation {
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: $fontRegular;
      color: $color-primary;
      margin-right: 8px;
      cursor: pointer;

      height: 20px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }
  }
  
}

@import '../../../../style/variables';

.map-marker-component {
  display: flex;
  flex-direction: row;

  width: 300px;

  .pin {
    width: 24px;
    height: 24px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
  }

  .text {
    font-size: 16px;
    font-family: $fontMedium;
  }

  &.delivery {
    color: $color-blue;
  }

  &.pick_up {
    color: $color-orange;
  }

  &.dump_yard {
    color: $color-dumpyard;
  }
}

@import '../../../../style/variables';

.map-view-component {
  display: flex;
  flex: 1;
  flex-direction: row;

  margin-right: 24px;

  .options-container {
    display: flex;
    flex-direction: column;

    width: 240px;
    margin-left: 20px;
  }

  .style-container {
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid $color-gray40;

    margin-bottom: 10px;

    .style-title {
      font-family: $fontSemiBold;;

      margin-bottom: 10px;
    }

    .style-item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      cursor: pointer;
      margin-bottom: 4px;
    }
  }

  .filters-container {
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid $color-gray40;

    margin-bottom: 10px;

    .title {
      font-family: $fontSemiBold;;

      margin-bottom: 10px;
    }

    .item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      cursor: pointer;
      margin-bottom: 4px;
    }

    .stop-types-container {
      margin-top: 10px;
      margin-bottom: 10px;
  
      .item-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
  
        cursor: pointer;
        margin-bottom: 4px;
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: $color-gray40;

      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

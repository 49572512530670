@import '../../../style/variables';

.drivers-schedule-table {
  display: flex;
  width: 100%;

  .header-cell {
    font-size: 12px;
    line-height: 18px;
    font-family: $fontLight;
    color: $color-gray60;
    text-transform: uppercase;
  }

  .data-cell {
    font-size: 14px;
    line-height: 18px;
    font-family: $fontRegular;
    color: $color-gray100;
  }

  .type-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 80px;
    border-radius: 11px;
    font-size: 12px;
  }

  .delivery {
    background-color: #9DBFE4;
  }

  .pick-up {
    background-color: #FFDDAA;
  }

  .dump-yard {
    background-color: #AED8D1;
  }

  .dumpster-size-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 80px;
    border-radius: 11px;
    font-size: 12px;
    background-color: $color-gray40;
  }

  .order-cell-container {
    display: flex;
    flex-direction: 'row';
    align-items: 'center';

    .icon {
      height: 16px;
      width: 16px;
      color: $color-gray80;
      cursor: pointer;
    }

    .value {
      margin-left: 4px;
      margin-right: 4px;
    }

    .hidden {
      opacity: 0;
    }
  }

  .over {
    background-color: #ADD8E6;
  }
}

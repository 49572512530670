@import '../../../style/variables';

.delete-order-modal-container {
	display: flex;
	flex-direction: column;

	padding: 32px 80px;
	width: 440px;
	position: relative;

	.close-button {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	.title {
		font-size: 24px;
		line-height: 32px;
		font-family: $fontRegular;
		color: $color-black;
		align-self: center;

		margin-bottom: 24px;
	}

	.buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		.button {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			align-self: center;
			width: 200px;
			height: 50px;
			background-color: $color-primary;
			text-align: center;
			border-radius: 5px;
			border: none;
			text-transform: uppercase;
			cursor: pointer;
			margin-right: 12px;

			font-size: 16px;
			line-height: 32px;
			font-family: $fontMedium;
			color: $color-black;

			margin-top: 24px;
		}

		.secondary-button {
			background-color: $color-white;
			color: $color-primary;
			border: 1px solid $color-primary;
		}
	}
}

@import '../../../style/variables';

.scheduled-dump-yard-item-component {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  margin-bottom: 5px;

  .order-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 16px;
    margin-right: 8px;

    .order-text {
      font-size: 12px;
      font-family: $fontMedium;
      color: $color-gray100;
    }

    .shift-button {
      width: 16px;
      height: 16px;
      color: $color-gray100;
      cursor: pointer;
    }
  }

  .dump-yard-content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    border-radius: 4px;
    padding: 4px;

    cursor: grab !important;

    &.dump-yard {
      background-color: #AED8D1;
    }
  
    &.storage {
      background-color: $color-storage;
    }

    &.quick-stop {
      background-color: $color-quickstop;
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 4px;
  }

  .name-text {
    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    max-width: 150px;
  }

  .address-text {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
  }

  .instructions-text {
    display: flex;
    flex-wrap: wrap;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    margin-top: 4px;
  }


  .x-icon {
    width: 20px;
    height: 20px;
    color: $color-error;
    cursor: pointer;
  }
}

@import "../../../style/variables";

.pickup-item-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;

  background-color: #ffddaa;
  border-radius: 4px;
  padding: 4px;
  margin: 4px 0px;

  cursor: grab !important;

  &.junk {
    background-color: darken(#ffddaa, 25%);
  }

  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 4px;
  }

  .name-text {
    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    max-width: 150px;
  }

  .size-text {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    padding: 2px 4px;
    border-radius: 2px;

    font-size: 12px;
    font-family: $fontMedium;
    color: $color-gray100;
    margin-right: 4px;
  }

  .address-text {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
  }

  .instructions-text {
    display: flex;
    flex-wrap: wrap;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    margin-top: 4px;
  }

  .margin-right {
    margin-right: 4px;
  }
}

@import '../../../../style/variables';

.received-orders-widget-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 250px;
  height: 200px;
  padding: 16px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid $color-gray40;
  cursor: pointer;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;

    .title {
      font-size: 18px;
      line-height: 24px;
      font-family: $fontMedium;
      color: $color-black;
    }
  }

  .title-total {
    display: flex;
    align-self: center;

    font-size: 14px;
    line-height: 18px;
    font-family: $fontLight;
    color: $color-gray100;

    margin-bottom: 16px;
    
    .title-total-count {
      color: $color-black;
      font-family: $fontMedium;
      margin-left: 4px;
    }
  }
  
  .icon {
    height: 40px;
    width: 40px;
    color: $color-gray80;
    align-self: center;
  }

  .cta-container {
    height: 26px;
  }
}

@import '../../style/variables';

.create-order-component {
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    padding-top: 32px;
    padding-bottom: 32px;
    .create-order-form-component {
      padding: 0;
      width: 100%;
      min-width: 320px;
      .field-container {
        width: 70%;
        min-width: 280px;
        margin-left: auto;
        margin-right: auto;

        .text-input {
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 600px) {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    .create-order-form-component {
      width: 440px;
      margin-left: auto;
      margin-right: auto;
      .text-input {
        width: 400px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    width: 440px;
    padding: 32px 82px 32px;
    .text-input {
      width: 400px;
    }
  }
  display: flex;
  flex-direction: column;
  align-self: center;
  height: calc(100vh - 80px - 24px - 64px);
  background-color: $color-white;
  margin-bottom: 24px;
  border-radius: 8px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .create-order-form-component {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .fields-scroll-container {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .field-container {
      margin-bottom: 16px;
  
      .label {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-gray80;
        margin-bottom: 4px;
      }
  
      .text-input {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-black;
  
        padding: 8px 12px;
        @media screen and (max-width: 600px) {
          padding-right: 0;
        }
        border-radius: 5px;
        border: 1px solid $color-gray40;

        &:focus {
          border: 1px solid $color-primary; 
        }
      }

      .disclaimer-list {
        padding-left: 16px;

        .disclaimer-text {
          font-size: 12px;
          font-family: $fontRegular;
          color: $color-gray80;
        }
      }

      .radio-button-options-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .create-order-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
      width: 200px;
      height: 50px !important;
      background-color: $color-primary;
      text-align: center;
      border-radius: 5px;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 12px;

      font-size: 16px;
      line-height: 32px;
      font-family: $fontMedium;
      color: $color-black;

      margin-top: 24px;
      padding: 8px;
    }

    .create-order-button:disabled {
      opacity: 40%;
    }

    .form-error {
      font-size: 12px;
      line-height: 14px;
      font-family: $fontRegular;
      color: $color-error;
      margin-top: 8px;
    }

    .termsAndConditionsText {
      font-size: 14px;
      line-height: 18px;
      font-family: $fontRegular;
      color: $color-gray80;
      margin-left: 10px;
    }

    .termsAndConditionsLink {
      color:blue;
      text-decoration:underline;
    }
    .termsAndConditionsLink:hover {
      cursor: pointer;
      text-decoration:none;
    }
    .termsAndConditionsLink:active {
      cursor: pointer;
      text-decoration:none;
      opacity: 70%;
    }
  }
}

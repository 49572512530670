@import '../../../../style/variables';

.pagination-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid $color-border;

  .copy {
    color: $color-gray80;
    font-family: $fontRegular;
    line-height: 20px;
    font-size: 14px;
  }

  .controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

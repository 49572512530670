@import '../../../../style/variables';

.received-orders-view-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;

  overflow: hidden;
  background-color: $color-gray0;

  &, & > * {
    box-sizing: border-box;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-right: 16px;
    margin-bottom: 24px;

    .title-text {
      color: $color-black;
      font-family: $fontSemiBold;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-right: 0.8rem;
    }

    .title-count {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-gray40;
      border-radius: 0.4rem;
      color: $color-gray80;
      font-family: $fontLight;
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding: 0.4rem;
    }
  }

  .orders-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex: 1;
    overflow-y: auto;
  }
}

@import '../../../../style/variables';

.punch-cards-view-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;

  overflow: scroll;
  background-color: $color-gray0;

  &, & > * {
    box-sizing: border-box;
  }
}

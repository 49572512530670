@import '../../../style/variables';

.material-disposing-pill {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 2px;

  font-size: 12px;
  font-family: $fontMedium;
  color: $color-gray100;
}

@import '../../../../style/variables';

.active-orders-card-component {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  margin-bottom: 24px;
  width: 100%;

  .title {
    color: $color-black;
    font-family: $fontSemiBold;
    line-height: 24px;
    font-size: 18px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
  }

  .order-history-button {
    border: 1px solid $color-blue;
    border-radius: $border-radius;
    padding: 8px 16px;

    color: $color-blue;
    font-family: $fontMedium;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;

    :hover {
      cursor: pointer;
    }
  }
}

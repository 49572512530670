@import '../../../../style/variables';

.client-dashboard-component {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    padding: 24px;
    background-color: $color-gray0;

    & > * {
        box-sizing: border-box;
    }
}

@import '../../../style/variables';

.update-dump-yard-instructions-modal-component {
  display: flex;
  flex-direction: column;

  padding: 32px 80px;
  width: 440px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .update-dump-yard-instructions-form {
    display: flex;
    flex-direction: column;

    .field-container {
      margin-bottom: 16px;
  
      .label {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-gray80;
        margin-bottom: 4px;
      }
  
      .text-input {
        width: 400px;
  
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-black;
        
        margin-bottom: 4px;
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid $color-gray40;
  
        &:focus {
          border: 1px solid $color-primary; 
        }
      }

      .form-error {
        font-size: 12px;
        line-height: 14px;
        font-family: $fontRegular;
        color: $color-error;
      }
    }

    .update-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
      width: 300px;
      height: 50px;
      background-color: $color-primary;
      text-align: center;
      border-radius: 5px;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 12px;
  
      font-size: 16px;
      line-height: 32px;
      font-family: $fontMedium;
      color: $color-black;
  
      margin-top: 24px;
    }
  }

  .error-text {
    font-size: 14px;
    line-height: 18px;
    font-family: $fontRegular;
    color: $color-error;
    margin-top: 8px;
  }
}

@import '../../../../style/variables';

.edit-punch-card-modal-component {
  display: flex;
  flex-direction: column;

  width: 560px;
  padding: 32px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    padding: 0;
  }

  .title {
    font-size: 24px;
    font-family: $fontRegular;
    line-height: 24px;
    color: $color-black;
    margin-bottom: 32px;
  }
}

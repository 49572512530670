@import '../../style/variables';

.cancel-order-component {
  @media screen and (max-width: 600px) {
    padding: 32px 0;
    width: 100%;

    .cancel-order-form-component {
      margin-top: auto;
      margin-bottom: auto;
      .field-container {
        width: 80%;
        min-width: 270px;
        margin-left: auto;
        margin-right: auto;
      }
      .text-input {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 600px) {
    padding: 32px 80px;
    width: 440px;
    .text-input {
      width: 400px;
    }
  }

  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;

  background-color: $color-white;
  border-radius: 8px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .cancel-order-form-component {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .field-container {
      margin-bottom: 16px;
  
      .label {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-gray80;
        margin-bottom: 4px;
      }
  
      .text-input {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-black;
  
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid $color-gray40;

        &:focus {
          border: 1px solid $color-primary; 
        }
      }
    }

    .cancel-order-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
      width: 200px;
      height: 50px;
      background-color: $color-primary;
      text-align: center;
      border-radius: 5px;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 12px;

      font-size: 16px;
      line-height: 32px;
      font-family: $fontMedium;
      color: $color-black;

      margin-top: 24px;
    }

    .form-error {
      font-size: 12px;
      line-height: 14px;
      font-family: $fontRegular;
      color: $color-error;
      margin-top: 8px;
    }
  }
}

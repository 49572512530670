@import "./variables";

.form-field-container {
  width: 320px;
  margin-bottom: 16px;

  .form-field-text {
    width: 300px;
    height: 32px;
    border-radius: 5px;
    border: 1px;
    background-color: $color-gray100;
    padding: 8px 10px;
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 18px;
    font-family: $fontMedium;
    color: $color-white;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-gray60;
    }
  }

  .form-error {
    font-size: 12px;
    line-height: 14px;
    font-family: $fontRegular;
    color: $color-error;
  }
}

input:focus {
  outline: none !important;
}

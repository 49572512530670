@import '../../../../style/variables';

.pending-orders-card-component {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  padding-top: 24px;
  width: 100%;

  .title {
    color: $color-black;
    font-family: $fontSemiBold;
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 24px;
    margin-left: 24px;
  }
}

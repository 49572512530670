@import '../../../../style/variables';

.back-button-component {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 88px;
  padding: 8px 16px;
  background-color: $color-white;
  border-radius: $border-radius;
  cursor: pointer;

  .copy {
    color: $color-black;
    font-family: $fontMedium;
    line-height: 20ox;
    font-size: 14px;
    margin-left: 8px;
  }
}

@import '../../../style/variables';

.schedule-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;

  background-color: $color-gray0;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: 20px;

    .icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    .name-text {
      font-size: 18px;
      line-height: 24px;
      font-family: $fontMedium;
      color: $color-gray100;
    }
  }

  .date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon-container {
      height: 24px;
      width: 24px;
      margin-right: 8px;
      cursor: pointer;

      .icon {
        height: 24px;
        width: 24px;
        color: $color-gray80;
      }
    }
    
    .date-text {
      font-size: 18px;
      line-height: 24px;
      font-family: $fontMedium;
      color: $color-gray100;
      margin-left: 16px;
    }
  }

  .print-button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 20px;
    font-family: $fontSemiBold;
    color: $color-black;
    margin-right: 8px;
    padding: 8px;
    cursor: pointer;

    height: 20px;
    width: 160px;
    border-radius: 5px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    margin-right: 16px;
  }
}

@import '../../../../style/variables';

.overview-card-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.4rem;
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 16px;
    background-color: $color-blue;
    border-radius: $border-radius;
  }

  .title-container {
    .title {
      color: $color-black;
      font-family: $fontSemiBold;
      line-height: 40px;
      font-size: 32px;
      margin-bottom: 4px;
    }

    .subtitle {
      color: $color-gray80;
      font-family: $fontRegular;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

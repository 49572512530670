@import '../../../style/variables';

.create-schedule-modal-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 80px;
  width: 440px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    margin-right: 16px;
  }

  .driver-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 300px;
    border: 1px solid $color-gray40;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .driver-action {
    text-decoration: underline;
    cursor: pointer;
  }
}

@font-face {
    font-family: Inter-Black;
    src: url(../assets/fonts/Inter-Black.ttf);
}
@font-face {
    font-family: Inter-Bold;
    src: url(../assets/fonts/Inter-Bold.ttf);
}
@font-face {
    font-family: Inter-ExtraBold;
    src: url(../assets/fonts/Inter-ExtraBold.ttf);
}
@font-face {
    font-family: Inter-ExtraLight;
    src: url(../assets/fonts/Inter-ExtraLight.ttf);
}
@font-face {
    font-family: Inter-Light;
    src: url(../assets/fonts/Inter-Light.ttf);
}
@font-face {
    font-family: Inter-Medium;
    src: url(../assets/fonts/Inter-Medium.ttf);
}
@font-face {
    font-family: Inter-Regular;
    src: url(../assets/fonts/Inter-Regular.ttf);
}
@font-face {
    font-family: Inter-SemiBold;
    src: url(../assets/fonts/Inter-SemiBold.ttf);
}
@font-face {
    font-family: Inter-Thin;
    src: url(../assets/fonts/Inter-Thin.ttf);
}

// Fonts
$fontBlack: 'Inter-Black';
$fontExtraBold: 'Inter-ExtraBold';
$fontBold: 'Inter-Bold';
$fontSemiBold: 'Inter-SemiBold';
$fontMedium: 'Inter-Medium';
$fontRegular: 'Inter-Regular';
$fontLight: 'Inter-Light';
$fontExtraLight: 'Inter-ExtraLight';
$fontThin: 'Inter-Thin';

// Colors
$color-primary: #FBD20A;

$color-black: #131313;
$color-white: #FFFFFF;

$color-blue: #0A60BB;
$color-orange: #FEAA2B;
$color-error: #FF5366;
$color-success: #70D27C;

$color-gray100: #333333;
$color-gray80: #797C80;
$color-gray60: #B3B3B3;
$color-gray40: #DAE0E6;
$color-gray20: #D6D8DA;
$color-gray10: #EAEAEA;
$color-gray0: #F8F8F8;

$color-border: #DAE0E6;

$color-pickup: #FFDDAA;
$color-delivery: #9DBFE4;
$color-dumpyard: #013220;
$color-storage: #A89D92;
$color-quickstop: #FBD20A;
$border-radius: 8px;

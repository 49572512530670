@import '../../../style/variables';

.orders-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;

  overflow: hidden;
  background-color: $color-gray0;

  &, & > * {
    box-sizing: border-box;
  }

  .table-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;

    border-radius: 5px;
    border: 1px solid $color-gray40;

    .header-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .sorting-icon {
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }

    .delete-icon {  
      margin-left: 8px;
    }

    .open-icon {
      height: 16px;
      width: 16px;
      color: $color-gray100;
    }

    .update-icon-container {
      height: 16px;
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
    }

    .update-icon {
      height: 16px;
      width: 16px;
      color: $color-gray100;
    }

    .instructions-icon-container {
      height: 16px;
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
    }

    .instructions-icon {
      height: 16px;
      width: 16px;
      color: $color-gray100;
    }
  }

  .controllers-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin-bottom: 24px;

    .left-controllers-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-right: 16px;

      .title-text {
        color: $color-black;
        font-family: $fontSemiBold;
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-right: 0.8rem;
      }

      .title-count {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray40;
        border-radius: 0.4rem;
        color: $color-gray80;
        font-family: $fontLight;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.4rem;
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      width: 30rem;
      height: 22px;

      border-radius: 5px;
      background-color: $color-gray40;
      margin-right: 24px;
      padding: 8px 16px;

      input {
        display: flex;
        flex: 1;

        border: none;
        background-color: $color-gray40;

        font-size: 16px;
        line-height: 20px;
        font-family: $fontRegular;
        color: $color-gray100;
      }

      .search-icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    .icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
  
      font-size: 16px;
      line-height: 20px;
      font-family: $fontSemiBold;
      color: $color-primary;
      padding: 8px;
      cursor: pointer;
  
      height: 20px;
      width: 120px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }

    .refresh-button {
      display: flex;
      align-items: center;
      justify-content: center;
  
      font-size: 16px;
      line-height: 20px;
      font-family: $fontSemiBold;
      color: $color-primary;
      margin-right: 24px;
      padding: 8px;
      cursor: pointer;
  
      height: 20px;
      width: 20px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }

    .filter-button {
      display: flex;
      align-items: center;
      justify-content: center;
  
      font-size: 16px;
      line-height: 20px;
      font-family: $fontSemiBold;
      color: $color-primary;
      padding: 8px;
      cursor: pointer;
  
      height: 20px;
      width: 120px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }
  }
}

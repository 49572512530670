@import '../../../../style/variables';

.active-orders-table-component {
  display: flex;
  overflow-x: auto;
  overflow-y: auto;

  .actions-cell {
    .view-profile-button {
      background-color: $color-blue;
      border-radius: $border-radius;
      padding: 8px 16px;

      color: $color-white;
      font-family: $fontMedium;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;

      :hover {
        cursor: pointer;
      }
    }
  }

  &.empty-state {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      font-size: 18px;
      line-height: 24px;
      font-family: $fontRegular;
      color: $color-black;
      margin-bottom: 16px;
    }
  }
}

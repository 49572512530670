@import './variables';

.tp-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 40px;
    text-align: left;
    
    font-size: 14px;
    line-height: 18px;
    font-family: $fontMedium;
    color: $color-black;

    border-radius: 5px;
    border-bottom: 1px solid $color-gray40;
  }

  tbody {
    tr {
      height: 40px;

      &:not(:last-child) {
        border-bottom: 1px solid $color-gray40;
      }

      td {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-black;
      }
    }
  }

  td, th {
    padding: 8px 16px;
  }

  .actions-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .delete-icon {
    width: 16px;
    height: 16px;
    color: $color-error;
    cursor: pointer;
  }
}

.new-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 60px;
    text-align: left;
    
    font-size: 14px;
    line-height: 20px;
    font-family: $fontRegular;
    color: $color-black;

    background-color: $color-gray10;
    border-bottom: 1px solid $color-border;
  }

  tbody {
    tr {
      height: 72px;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }

      &:hover {
        background-color: $color-gray40;
      }

      &.grayed-out {
        background-color: $color-gray100;
      }

      td {
        font-size: 14px;
        line-height: 20px;
        font-family: $fontRegular;
        color: $color-black;
      }
    }
  }

  td, th {
    padding: 8px 16px;
  }
}

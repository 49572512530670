@import '../../../style/variables';

.login-component {
  display: flex;
  flex-direction: row-reverse;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .layout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 50vw;
    height: 100vh;
    margin-top: -80px; // height of navbar
    background-color: $color-black;

    .content-container {
      .title {
        font-size: 24px;
        line-height: 32px;
        font-family: $fontSemiBold;
        color: $color-white;
        margin-bottom: 32px;
      }
  
      .login-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
  
        .already-text {
          font-size: 16px;
          line-height: 20px;
          font-family: $fontRegular;
          color: $color-gray60;
          margin-right: 8px;
        }
  
        .login-text {
          font-size: 16px;
          line-height: 20px;
          font-family: $fontSemiBold;
          color: $color-primary;
        }
        
        .login-link {
          text-decoration: none;
        }
      }

      .button-container {
        margin-bottom: 24px;

        .login-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 320px;
          height: 50px;
          background-color: $color-primary;
          text-align: center;
          border-radius: 5px;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
  
          font-size: 16px;
          line-height: 32px;
          font-family: $fontBold;
          color: $color-black;
        }

        .error-text {
          font-size: 12px;
          line-height: 14px;
          font-family: $fontRegular;
          color: $color-error;
          margin-top: 8px;
        }
      }
    }
  }
}

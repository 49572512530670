@import '../../style/variables';

.date-range-picker-component {
  display: flex;
  flex-direction: row;

  .from {
    margin-right: 16px;
  }
}

@import "../../../style/variables";

.scheduled-pickup-item-component {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  margin-bottom: 5px;

  .order-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 16px;
    margin-right: 8px;

    .order-text {
      font-size: 12px;
      font-family: $fontMedium;
      color: $color-gray100;
    }

    .shift-button {
      width: 16px;
      height: 16px;
      color: $color-gray100;
      cursor: pointer;
    }
  }

  .pickup-content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    border-radius: 4px;
    padding: 4px;
    background-color: #ffddaa;

    cursor: grab !important;

    &.junk {
      background-color: darken(#ffddaa, 25%);
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    max-width: 200px;
    margin-bottom: 4px;
  }

  .name-text {
    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    max-width: 150px;
  }

  .size-text {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    padding: 2px 4px;
    border-radius: 2px;

    font-size: 12px;
    font-family: $fontMedium;
    color: $color-gray100;
    margin-right: 4px;
  }

  .address-text {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
  }

  .instructions-text {
    display: flex;
    flex-wrap: wrap;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;
    margin-top: 4px;
  }

  .x-icon {
    width: 20px;
    height: 20px;
    color: $color-error;
    cursor: pointer;
  }

  .priority-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    font-family: $fontMedium;
    color: $color-gray100;

    margin-top: 4px;

    .control-button {
      background-color: #ffffff;
      padding: 2px 4px;
      border-radius: 2px;

      cursor: pointer;
    }

    .label {
      background-color: #ffffff;
      padding: 2px 4px;
      border-radius: 2px;
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  .margin-right {
    margin-right: 4px;
  }
}

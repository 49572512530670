@import '../../../../style/variables';

.punch-cards-card-component {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-border;
  padding-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    
    .left-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        color: $color-black;
        font-family: $fontSemiBold;
        line-height: 24px;
        font-size: 18px;
        margin-right: 16px;
      }
    }

    .right-container {

    }

  }
}

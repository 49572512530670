@import '../../../../style/variables';

.received-order-card-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 16px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid $color-gray40;

  &, & > * {
    box-sizing: border-box;
  }

  .info-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    .info-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .info-label {
        width: 150px;
        margin-right: 8px;
      }
    }
  }

  .approve-order-form-component {
    display: flex;
    flex-direction: row;
    flex: 1;

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;

      .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;

        .label {
          font-size: 14px;
          line-height: 18px;
          font-family: $fontRegular;
          color: $color-gray80;
        }

        .text-input {
          font-size: 14px;
          line-height: 18px;
          font-family: $fontRegular;
          color: $color-black;
          
          padding: 8px 12px;
          border-radius: 5px;
          border: 1px solid $color-gray40;
  
          &:focus {
            border: 1px solid $color-blue;
          }
        }

        .width-150 {
          width: 176px;
          margin-right: 4px;
        }

        .width-50 {
          width: 76px;
          margin-right: 4px;
        }

        .remove-icon {
          height: 16px;
          width: 16px;
          color: $color-error;
          cursor: pointer;
          margin-left: 12px;
          margin-top: 9px;
        }

        .field {
          display: flex;
          flex-direction: column;
        }

        .field-error {
          font-size: 12px;
          line-height: 14px;
          font-family: $fontRegular;
          color: $color-error;
        }
      }

      .add-item-button {
        align-self: center;
        font-size: 14px;
        line-height: 18px;
        color: $color-blue;
        text-align: center;
        cursor: pointer;
        margin-top: 16px;
      }
    }

    .column-title {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontRegular;
      color: $color-gray100;
      margin-bottom: 4px;
    }

    .field-container {
      margin-bottom: 16px;
  
      .label {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-gray80;
        margin-bottom: 4px;
      }
  
      .text-input {
        font-size: 14px;
        line-height: 18px;
        font-family: $fontRegular;
        color: $color-black;
  
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid $color-gray40;

        &:focus {
          border: 1px solid $color-blue;
        }
      }
    }

    .form-error {
      max-width: 192px;
      font-size: 12px;
      line-height: 14px;
      font-family: $fontRegular;
      color: $color-error;
      margin-top: 8px;
    }
  }

  .vertical-divider {
    width: 1px;
    height: 100%;
    background-color: $color-gray40;
    margin-left: 16px;
    margin-right: 16px;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
  }

  .button {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid $color-blue;
    color: $color-blue;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.selected {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}

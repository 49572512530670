@import '../../../../style/variables';

.edit-punch-card-form {
  display: flex;
  flex-direction: column;

  .date-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  .time-picker-container {
    width: 200px;
    margin-bottom: 16px;
  }

  .view-button {
    align-self: center;
    margin-top: 16px;

    background-color: $color-blue;
    border-radius: $border-radius;
    padding: 8px 16px;

    color: $color-white;
    font-family: $fontMedium;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
  }
}

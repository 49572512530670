@import '../../style/variables';

.create-order-confirmation-component {
  @media screen and (max-width: 600px) {
    padding: 32px 0;
    width: 100%;
  }
  @media screen and (min-width: 600px) {
    padding: 32px 80px;
    width: 440px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;

  background-color: $color-white;
  margin-top: 150px;
  margin-bottom: 24px;
  border-radius: 8px;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;

    margin-bottom: 24px;
  }

  .confirmation-icon {
    margin-bottom: 24px;
  }

  .order-id-text {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontRegular;
    color: $color-black;
    align-self: center;
  }

  .order-id-value-text {
    font-size: 24px;
    line-height: 32px;
    font-family: $fontSemiBold;
    color: $color-black;
    align-self: center;
  }
}

@import "../../../../style/variables";

.reports-widget-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 250px;
  height: 200px;
  padding: 16px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid $color-gray40;

  .title {
    font-size: 18px;
    line-height: 24px;
    font-family: $fontMedium;
    color: $color-black;
  }

  .link {
    font-size: 14px;
    line-height: 18px;
    font-family: $fontRegular;
    color: $color-black;
    text-decoration: underline;
    cursor: pointer;
  }
}

@import '../../../style/variables';

.form-radio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid $color-gray40;

  font-size: 14px;
  line-height: 18px;
  font-family: $fontRegular;
  color: $color-black;

  cursor: pointer;
  margin-right: 12px;

  &.selected {
    border: 1px solid $color-primary;
    color: $color-primary;
    font-family: $fontBold;
  }
}

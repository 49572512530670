@import '../../../style/variables';

.schedule-driver-widget-component {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow-y: hidden;

  background-color: $color-white;
  border: 1px solid $color-gray40;
  border-radius: 5px;
  min-width: 256px;
  max-width: 256px;
  padding: 15px 8px;
  margin: 5px;

  .name-border {
    display: flex;
    width: 100%;
    border-bottom: 2px solid $color-gray40;
    margin-bottom: 10px;
  }

  .schedule-driver-header-container {
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    margin-bottom: 10px;

    .name-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .name-text {
      font-size: 12px;
      line-height: 14px;
      font-family: $fontSemiBold;
      color: $color-black;
      margin-right: 8px;
    }
    
    .schedule-icon {
      color: $color-gray100;
      cursor: pointer;
    }

    .total {
      font-size: 12px;
      line-height: 14px;
      font-family: $fontRegular;
      color: $color-black;
      margin-right: 10px;
    }

    .delete-icon {
      width: 16px;
      height: 16px;
      color: $color-error;
      cursor: pointer;
    }

  }
  
  .optimization-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 256px;
    margin-bottom: 12px;

    .details-container {
      display: flex;
      flex-direction: column;

      .item-container{
        display: flex;
        flex-direction: row;
        align-items: center;

        .item-label {
          width: 70px;

          font-size: 12px;
          line-height: 14px;
          font-family: $fontRegular;
          color: $color-black;
          margin-right: 8px;
        }

        .item-value {
          font-size: 12px;
          line-height: 14px;
          font-family: $fontSemiBold;
          color: $color-black;
        }
      }
    }
    
    .actions-container {
      display: flex;
      flex-direction: column;
    }

    .optimize-button {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: start;
  
      font-size: 12px;
      line-height: 14px;
      font-family: $fontSemiBold;
      color: $color-primary;
      padding: 8px;
      cursor: pointer;
  
      height: 16px;
      width: 80px;
      border-radius: 5px;
      border: 1px solid $color-primary;
    }

    .calculate-stats-button {
      font-size: 12px;
      line-height: 14px;
      font-family: $fontSemiBold;
      color: $color-black;
      padding: 8px;
      text-decoration: underline;
      
      cursor: pointer;
    }
  }

  .items-container {
    width: 256px;
    overflow-y: scroll;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .order-number {
    font-size: 12px;
    line-height: 14px;
    width: 20px;
  }

  .optimizing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.dropable {
  border: 1px dashed $color-gray80;
  background-color: #EEE;
}

.over {
  background-color: #ADD8E6;
}
